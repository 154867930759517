import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import FormDetail from "../../../components/Drive/Form/Detail.js"
import ReduxWrapper from "../../../redux/reduxWrapper"

const FormDetails = props => {
  return <PrivateRoute component={FormDetail} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<FormDetails {...props} />} />
export default WrappedPage
